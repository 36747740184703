import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import App from './App.vue'
import LandingPage from './views/LandingPage.vue'
import Tasks from './views/Tasks.vue'
import DecisionsPage from './views/Decisions.vue'
import LoginForm from './components/auth/LoginForm.vue'
import RegisterForm from './components/auth/RegisterForm.vue'
import authService from './services/auth'

// Create router
const router = createRouter({
  history: createWebHistory(),
  routes: [
    { 
      path: '/', 
      name: 'home',
      component: LandingPage,
    },
    { path: '/tasks', name: 'tasks', component: Tasks, meta: { requiresAuth: true } },
    { path: '/decisions', name: 'decisions', component: DecisionsPage, meta: { requiresAuth: true } },
    { path: '/login', name: 'login', component: LoginForm, meta: { guestOnly: true } },
    { path: '/register', name: 'register', component: RegisterForm, meta: { guestOnly: true } }
  ]
})

// Navigation guard
router.beforeEach((to, from, next) => {
  const isAuthenticated = authService.isAuthenticated()

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!isAuthenticated) {
      next({ name: 'home' })
    } else {
      next()
    }
  } else if (to.matched.some(record => record.meta.guestOnly)) {
    if (isAuthenticated) {
      next({ name: 'home' })
    } else {
      next()
    }
  } else {
    next()
  }
})

// Create and mount the Vue application
const app = createApp(App)
app.use(router)
app.mount('#app')
