<template>
  <form @submit.prevent="login" v-if="!isAuthenticated">
    <div>
      <label for="email">Email:</label>
      <input type="email" id="email" v-model="email" required>
    </div>
    <div>
      <label for="password">Password:</label>
      <input type="password" id="password" v-model="password" required>
    </div>
    <button type="submit">Login</button>
    <p v-if="error" class="error">{{ error }}</p>
    <p v-if="successMessage" class="success">{{ successMessage }}</p>
  </form>
</template>

<script>
import authService from '@/services/auth';

export default {
  data() {
    return {
      email: '',
      password: '',
      error: null,
      successMessage: null,
      isAuthenticated: false
    };
  },
  created() {
    this.checkAuthentication();
  },
  methods: {
    checkAuthentication() {
      this.isAuthenticated = authService.isAuthenticated();
      if (this.isAuthenticated) {
        this.$router.push('/');
      }
    },
    async login() {
      this.error = null;
      this.successMessage = null;
      try {
        const response = await authService.login(this.email, this.password);
        console.log('Login response:', response);
        if (response.accessToken) {
          this.successMessage = 'Login successful!';
          // Redirect to home page after a short delay
          setTimeout(() => {
            this.$router.push('/');
          }, 500);
        } else {
          this.error = 'Login failed. Access token not received.';
        }
      } catch (error) {
        console.error('Login error:', error);
        if (error.response) {
          console.error('Error response:', error.response.data);
          console.error('Error status:', error.response.status);
        }
        this.error = `An error occurred during login: ${error.message}`;
      }
    }
  }
};
</script>

<style scoped>
.error {
  color: red;
}
.success {
  color: green;
}
</style>
