<template>
  <div class="decisions">
    <h1>Decisions</h1>
    <button @click="showForm = true">Create New Decision</button>

    <DecisionForm
      v-if="showForm"
      :editDecision="editingDecision"
      @decision-submitted="handleDecisionSubmitted"
      @cancel="handleCancel"
    />

    <DecisionList
      @edit-decision="handleEditDecision"
      :key="listKey"
    />
  </div>
</template>

<script>
import DecisionForm from '@/components/decisions/DecisionForm.vue';
import DecisionList from '@/components/decisions/DecisionList.vue';

export default {
  name: 'DecisionPage',
  components: {
    DecisionForm,
    DecisionList
  },
  data() {
    return {
      showForm: false,
      listKey: 0,
      editingDecision: null
    };
  },
  methods: {
    handleDecisionSubmitted() {
      this.showForm = false;
      this.editingDecision = null;
      this.listKey += 1; // Force DecisionList to re-render
    },
    handleEditDecision(decision) {
      this.editingDecision = decision;
      this.showForm = true;
    },
    handleCancel() {
      this.showForm = false;
      this.editingDecision = null;
    }
  }
};
</script>
