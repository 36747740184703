import axios from 'axios';
import authService from './auth';

const api = axios.create({
  baseURL: 'http://localhost:5075/', // Update with your API URL
});

api.interceptors.request.use(
  async (config) => {
    let token = authService.getToken();
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response.status === 401) {
      authService.logout();
    }
    return Promise.reject(error);
  }
);

export default api;
