<template>
  <div id="app">
    <header>
      <nav>
        <router-link :to="{ name: 'home' }" class="logo">ManagerCopilot</router-link>
        <div class="nav-links">
          <template v-if="isLoggedIn">
            <router-link :to="{ name: 'tasks' }">Tasks</router-link>
            <router-link :to="{ name: 'decisions' }">Decisions</router-link>
            <a href="#" @click.prevent="logout">Logout</a>
          </template>
          <template v-else>
            <router-link :to="{ name: 'login' }">Login</router-link>
            <router-link :to="{ name: 'register' }">Register</router-link>
          </template>
        </div>
      </nav>
    </header>
    <main>
      <router-view></router-view>
    </main>
  </div>
</template>

<script>
import { ref, onMounted, watch } from 'vue';
import { useRouter } from 'vue-router';
import authService from '@/services/auth';

export default {
  name: 'App',
  setup() {
    const router = useRouter();
    const isLoggedIn = ref(false);

    const updateAuthStatus = () => {
      isLoggedIn.value = authService.isAuthenticated();
    };

    const logout = () => {
      authService.logout();
      updateAuthStatus();
      router.push('/');
    };

    onMounted(() => {
      updateAuthStatus();
    });

    watch(() => router.currentRoute.value, () => {
      updateAuthStatus();
    });

    return {
      isLoggedIn,
      logout
    };
  }
};
</script>

<style>
@import './assets/styles/main.css';
</style>
