<template>
  <div>
    <h2>Decisions</h2>
    <p v-if="error" class="error">{{ error }}</p>
    <ul v-else-if="decisions.length">
      <li v-for="decision in decisions" :key="decision.id">
        {{ decision.title }}
        <button @click="editDecision(decision)">Edit</button>
        <button @click="deleteDecision(decision.id)">Delete</button>
      </li>
    </ul>
    <p v-else>No decisions found.</p>
  </div>
</template>

<script>
import api from '@/services/api';

export default {
  data() {
    return {
      decisions: [],
      error: null
    };
  },
  mounted() {
    this.fetchDecisions();
  },
  methods: {
    async fetchDecisions() {
      try {
        this.error = null;
        const response = await api.get('/Decisions');
        this.decisions = response.data;
      } catch (error) {
        console.error('Error fetching decisions:', error);
        this.error = `Error fetching decisions: ${error.message}`;
      }
    },
    editDecision(decision) {
      // Emit event to parent component to handle editing
      this.$emit('edit-decision', decision);
    },
    async deleteDecision(id) {
      try {
        await api.delete(`/Decisions/${id}`);
        this.fetchDecisions();
      } catch (error) {
        console.error('Error deleting decision:', error);
      }
    }
  }
};
</script>

<style scoped>
.error {
  color: red;
}
</style>
