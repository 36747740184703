<template>
  <form @submit.prevent="submitDecision">
    <div>
      <label for="title">Title:</label>
      <input type="text" id="title" v-model="decision.title" required>
    </div>
    <div>
      <label for="description">Description:</label>
      <textarea id="description" v-model="decision.description"></textarea>
    </div>
    <button type="submit">{{ isEditing ? 'Update' : 'Create' }} Decision</button>
    <button type="button" @click="cancel">Cancel</button>
  </form>
</template>

<script>
import api from '@/services/api';

export default {
  props: {
    editDecision: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      decision: {
        title: '',
        description: ''
      },
      isEditing: false
    };
  },
  watch: {
    editDecision: {
      handler(newDecision) {
        if (newDecision) {
          this.decision = { ...newDecision };
          this.isEditing = true;
        } else {
          this.resetForm();
        }
      },
      immediate: true
    }
  },
  methods: {
    async submitDecision() {
      try {
        if (this.isEditing) {
          await api.put(`/Decisions/${this.decision.id}`, this.decision);
        } else {
          await api.post('/Decisions', this.decision);
        }
        this.$emit('decision-submitted');
        this.resetForm();
      } catch (error) {
        console.error('Error submitting decision:', error);
      }
    },
    resetForm() {
      this.decision = { title: '', description: '' };
      this.isEditing = false;
    },
    cancel() {
      this.$emit('cancel');
      this.resetForm();
    }
  }
};
</script>
