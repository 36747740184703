<template>
  <form @submit.prevent="submitTask">
    <div>
      <label for="title">Title:</label>
      <input type="text" id="title" v-model="task.title" required>
    </div>
    <div>
      <label for="description">Description:</label>
      <textarea id="description" v-model="task.description"></textarea>
    </div>
    <button type="submit">{{ isEditing ? 'Update' : 'Create' }} Task</button>
    <button type="button" @click="cancel">Cancel</button>
  </form>
</template>

<script>
import api from '@/services/api';

export default {
  props: {
    editTask: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      task: {
        title: '',
        description: ''
      },
      isEditing: false
    };
  },
  watch: {
    editTask: {
      handler(newTask) {
        if (newTask) {
          this.task = { ...newTask };
          this.isEditing = true;
        } else {
          this.resetForm();
        }
      },
      immediate: true
    }
  },
  methods: {
    async submitTask() {
      try {
        if (this.isEditing) {
          await api.put(`/Tasks/${this.task.id}`, this.task);
        } else {
          await api.post('/Tasks', this.task);
        }
        this.$emit('task-submitted');
        this.resetForm();
      } catch (error) {
        console.error('Error submitting task:', error);
      }
    },
    resetForm() {
      this.task = { title: '', description: '' };
      this.isEditing = false;
    },
    cancel() {
      this.$emit('cancel');
      this.resetForm();
    }
  }
};
</script>
