<template>
  <div>
    <h2>Tasks</h2>
    <p v-if="error" class="error">{{ error }}</p>
    <ul v-else-if="tasks.length">
      <li v-for="task in tasks" :key="task.id">
        {{ task.title }}
        <button @click="editTask(task)">Edit</button>
        <button @click="deleteTask(task.id)">Delete</button>
      </li>
    </ul>
    <p v-else>No tasks found.</p>
  </div>
</template>

<script>
import api from '@/services/api';

export default {
  data() {
    return {
      tasks: [],
      error: null
    };
  },
  mounted() {
    this.fetchTasks();
  },
  methods: {
    async fetchTasks() {
      try {
        this.error = null;
        const response = await api.get('/Tasks');
        this.tasks = response.data;
      } catch (error) {
        console.error('Error fetching tasks:', error);
        this.error = `Error fetching tasks: ${error.message}`;
      }
    },
    editTask(task) {
      this.$emit('edit-task', task);
    },
    async deleteTask(id) {
      try {
        await api.delete(`/Tasks/${id}`);
        this.fetchTasks();
      } catch (error) {
        console.error('Error deleting task:', error);
      }
    }
  }
};
</script>

<style scoped>
.error {
  color: red;
}
</style>
