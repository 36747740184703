<template>
  <div class="landing-page">
    <template v-if="!isAuthenticated">
      <h1>Welcome to ManagerCopilot</h1>
      <p>Your AI-powered assistant for engineering managers</p>
      
      <section class="features">
        <h2>Features</h2>
        <ul>
          <li>AI-driven task prioritization</li>
          <li>Team performance analytics</li>
          <li>Automated meeting scheduling</li>
          <li>Decision support system</li>
        </ul>
      </section>
      
      <section class="pricing">
        <h2>Pricing</h2>
        <div class="pricing-plans">
          <div class="plan">
            <h3>Starter</h3>
            <p class="price">$49/month</p>
            <ul>
              <li>Up to 5 team members</li>
              <li>Basic analytics</li>
              <li>Email support</li>
            </ul>
          </div>
          <div class="plan">
            <h3>Pro</h3>
            <p class="price">$99/month</p>
            <ul>
              <li>Up to 15 team members</li>
              <li>Advanced analytics</li>
              <li>Priority support</li>
            </ul>
          </div>
          <div class="plan">
            <h3>Enterprise</h3>
            <p class="price">Contact us</p>
            <ul>
              <li>Unlimited team members</li>
              <li>Custom integrations</li>
              <li>24/7 support</li>
            </ul>
          </div>
        </div>
      </section>
      
      <section class="cta">
        <router-link to="/register" class="btn btn-primary">Get Started</router-link>
        <router-link to="/login" class="btn btn-secondary">Login</router-link>
      </section>
    </template>

    <template v-else>
      <h1>Welcome back, Manager!</h1>
      <dashboard-view />
    </template>
  </div>
</template>

<script>
import { computed } from 'vue';
import authService from '../services/auth';
import DashboardView from './Dashboard.vue'; // Import with the new name

export default {
  name: 'LandingPage',
  components: {
    DashboardView // Register with the new name
  },
  setup() {
    const isAuthenticated = computed(() => authService.isAuthenticated());

    return {
      isAuthenticated
    };
  }
}
</script>

<style scoped>
/* Add your styles here */
.pricing-plans {
  display: flex;
  justify-content: space-around;
}

.plan {
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 5px;
}

/* Add more styles as needed */
</style>
